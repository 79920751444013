var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Layout",
    [
      _c(
        "access-keeper",
        { attrs: { entity: "SUPPLY_LIST" } },
        [
          _c("user-header", { on: { newUserAdded: _vm.newUserAdded } }),
          _c("user-table", { ref: "userTable" }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }