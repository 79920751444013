<template>
  <div>
    <b-row class="pb-4">
      <b-col cols="6">
        <h2>{{ $t('users.titles.requests') }}</h2>
      </b-col>
      <b-col v-if="canCreateUser" cols="6" class="d-flex flex-row-reverse">
        <b-button variant="primary" @click="openModalAddUserInProvider">
          {{ $t('users.header.newUser') }}
        </b-button>
      </b-col>
    </b-row>
    <modal-user-add
      v-if="canCreateUser"
      ref="modalUserAdd"
      @close="provider = {}"
      @success="$emit('newUserAdded')"
    />
  </div>
</template>

<script>
import modalUserAdd from '@components/modals/provider/add-user';

export default {
  name: 'UserHeader',
  components: {
    modalUserAdd,
  },
  data() {
    return {
      contract: {},
      companyId: this.$route.params.contract_id,
    };
  },
  computed: {
    canCreateUser() {
      return this.$store.getters['user/groups'].includes('ACL:USER_SUPPLY:EDIT')
    },
  },
  methods: {
    backHistory() {
      this.$router.push({
        path: '/suplier/users',
      });
    },
    openModalAddUserInProvider() {
      this.$refs.modalUserAdd.$refs.modalUserAdd.show();
    },
  },
};
</script>

<style lang="sass">
.box-breadcrumb
  background: rgb(255,255,255)
  border: 1px solid rgb(234, 237, 243)
  border-radius: 4px
  padding: 20px
  color: rgb(62, 63, 66)
  font-size: 24px
  .title
    color: rgb(158, 160, 165)
    font-size: 12px
    text-transform: uppercase
</style>
