var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "users-list" },
    [
      _c("custom-data-table", {
        ref: "providerDataTable",
        attrs: {
          fields: _vm.fields,
          filters: _vm.filters,
          query: _vm.user_list_query,
          "per-page": null,
          "query-node": "supplyUsers",
        },
        on: { "vuetable:row-clicked": _vm.$_goTo },
        scopedSlots: _vm._u([
          {
            key: "contractName",
            fn: function (props) {
              return _c("span", {}, [
                _vm._v(_vm._s(_vm.getCompanyName(props.rowData.contract))),
              ])
            },
          },
          {
            key: "createdAt",
            fn: function (props) {
              return _c("span", {}, [
                _vm._v(
                  _vm._s(
                    _vm._f("moment")(props.rowData.createdAt, "DD/MM/YYYY")
                  )
                ),
              ])
            },
          },
          {
            key: "updatedAt",
            fn: function (props) {
              return _c("span", {}, [
                _vm._v(
                  _vm._s(
                    _vm._f("moment")(props.rowData.updatedAt, "DD/MM/YYYY")
                  )
                ),
              ])
            },
          },
          {
            key: "actions",
            fn: function (props) {
              return _c(
                "span",
                { staticStyle: { display: "inline-flex" } },
                [
                  _vm.canDeleteUser
                    ? _c(
                        "b-button",
                        {
                          staticClass: "edit-button danger",
                          attrs: {
                            title: _vm.$t("users.buttons.removeUser"),
                            variant: "link",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.deleteUser(props.rowData)
                            },
                          },
                        },
                        [_c("i", { staticClass: "fa fa-trash" })]
                      )
                    : _vm._e(),
                ],
                1
              )
            },
          },
        ]),
      }),
      _c("modal-provider-add", {
        ref: "modalProviderAdd",
        attrs: { provider: _vm.provider },
        on: {
          close: function ($event) {
            _vm.provider = {}
          },
          success: _vm.handleUserProvider,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }