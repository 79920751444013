<template>
  <div class="users-list">
    <custom-data-table
      ref="providerDataTable"
      :fields="fields"
      :filters="filters"
      :query="user_list_query"
      :per-page="null"
      query-node="supplyUsers"
      @vuetable:row-clicked="$_goTo"
    >
      <span
        slot="contractName"
        slot-scope="props"
      >{{ getCompanyName(props.rowData.contract) }}</span>
      <span
        slot="createdAt"
        slot-scope="props"
      >{{ props.rowData.createdAt | moment('DD/MM/YYYY') }}</span>
      <span
        slot="updatedAt"
        slot-scope="props"
      >{{ props.rowData.updatedAt | moment('DD/MM/YYYY') }}</span>
      <span slot="actions" slot-scope="props" style="display: inline-flex">
        <b-button
          v-if="canDeleteUser"
          :title="$t('users.buttons.removeUser')"
          variant="link"
          class="edit-button danger"
          @click.stop="deleteUser(props.rowData)"
        >
          <i class="fa fa-trash" />
        </b-button>
      </span>
    </custom-data-table>

    <modal-provider-add
      ref="modalProviderAdd"
      :provider="provider"
      @close="provider = {}"
      @success="handleUserProvider"
    />
  </div>
</template>

<script>
import { formatVat } from '@utils/numbers';
import CustomDataTable from '@components/CustomDataTable';
import GET_USER from '@graphql/supply/queries/get-user.gql';
import ModalProviderAdd from '@components/modals/provider/add';
import {
  remove as deleteSupplyUserService,
} from '@graphql/supply/services';

export default {
  name: 'UserTable',
  components: {
    CustomDataTable,
    ModalProviderAdd,
  },
  data() {
    return {
      provider: {},
      default_status: ['ACTIVE', 'PENDING', 'CLOSED'],
      user_list_query: GET_USER,
      fields: [
        {
          name: 'contractName',
          title: this.$t('users.tables.contractName'),
        },
        {
          name: 'email',
          title: this.$t('users.tables.email'),
        },
        {
          name: 'status',
          title: this.$t('users.tables.status'),
        },
        {
          name: 'createdAt',
          title: this.$t('users.tables.createdAt'),
        },
        {
          name: 'updatedAt',
          title: this.$t('users.tables.updatedAt'),
        },
        {
          name: 'actions',
          title: '',
        },
      ],
    };
  },
  computed: {
    canDeleteUser() {
      return this.$store.getters['user/groups'].includes('ACL:USER_SUPPLY:EDIT')
    },
    filters() {
      return {
        query: {
          type: 'textfield',
          props: {
            label: false,
            placeholder: this.$t('users.tables.usersSearchPlaceholder'),
          },
          graphqlQuery(value) {
            return { search: `username^="${value}"` };
          },
        },
      };
    },
  },
  methods: {
    getCompanyName(contract) {
      return contract?.company_name || 'N/A';
    },
    setVatFormat(vat) {
      return formatVat(vat);
    },
    $_goTo(_item = null) {
      _item = _item.data;
      this.setContract({
        id: _item.id,
        company: _item.name,
      });
    },
    openModalUpdateProvider(item) {
      this.provider = item;
      this.$refs.modalProviderAdd.$refs.modalProviderAdd.show();
    },
    handleUserProvider() {
      this.$refs.providerDataTable.$_refresh();
    },
    deleteUser(data) {
      const confirmationWindow = this.$swal.mixin({
        confirmButtonClass: 'btn btn-success',
        cancelButtonClass: 'btn btn-danger mr-3',
        buttonsStyling: false,
      });

      confirmationWindow({
        title: this.$t('users.confirmationWindow.modalConfirmationTitle'),
        text: `${this.$t('users.confirmationWindow.removeUser')} ${data.email}`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('users.confirmationWindow.confirm'),
        cancelButtonText: this.$t('users.confirmationWindow.cancel'),
        showLoaderOnConfirm: true,
        reverseButtons: true,
      }).then((result) => {
        if (!result.value) return;
        deleteSupplyUserService({ email: data.email })
          .then(() => {
            const message = this.$t(
              'users.confirmationWindow.userRemovedSuccessfull'
            );
            return this.successModal(message)
              .then(() => this.$emit('success'))
              .then(() => this.handleUserProvider());
          })
          .catch((err) => {
            this.$log.logError(err);
            this.showFailModal(err.message);
          })
      });
    },
    successModal(title) {
      const configSwal = {
        type: 'success',
        title,
        showConfirmButton: true,
        confirmButtonText: this.$t('modalUserAdd.words.close'),
      };

      return this.$swal(configSwal);
    },
    showFailModal() {
      const configSwal = {
        type: 'error',
        title: this.$t('modalUserAdd.texts.thereWasAnError'),
        showConfirmButton: true,
        confirmButtonText: this.$t('modalUserAdd.words.close'),
      };

      return this.$swal(configSwal);
    },
  },
};
</script>

<style lang="sass">
.vuetable-th-slot-actions
  width: 30px
.vuetable-th-slot-status
  width: 150px
.edit-button
  padding: 0
  margin: 0
.danger
  color: #dc3545
  &:hover
    color: red
.badge
  padding: 5px 10px
  color: white
  &-ACTIVE
    background: green
  &-PENDING
    background: orange
  &-CLOSED
    background: red
</style>
