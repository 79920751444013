<template>
  <Layout>
    <access-keeper entity="SUPPLY_LIST">
      <user-header @newUserAdded="newUserAdded" />
      <user-table ref="userTable" />
    </access-keeper>
  </Layout>
</template>

<script>
import Layout from '@layouts/main';
import UserHeader from '@components/users/user-header';
import UserTable from '@components/users/user-table';
import AccessKeeper from '@components/shared/region-guard/access-keeper'

export default {
  name: 'IndexUsers',
  components: {
    AccessKeeper,
    Layout,
    UserHeader,
    UserTable,
  },
  methods: {
    newUserAdded() {
      this.$refs.userTable.handleUserProvider();
    },
  },
};
</script>
