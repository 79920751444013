var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "pb-4" },
        [
          _c("b-col", { attrs: { cols: "6" } }, [
            _c("h2", [_vm._v(_vm._s(_vm.$t("users.titles.requests")))]),
          ]),
          _vm.canCreateUser
            ? _c(
                "b-col",
                {
                  staticClass: "d-flex flex-row-reverse",
                  attrs: { cols: "6" },
                },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "primary" },
                      on: { click: _vm.openModalAddUserInProvider },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("users.header.newUser")) + " ")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.canCreateUser
        ? _c("modal-user-add", {
            ref: "modalUserAdd",
            on: {
              close: function ($event) {
                _vm.provider = {}
              },
              success: function ($event) {
                return _vm.$emit("newUserAdded")
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }